const NavClose = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 0H0V22H22V0Z" fill="white" fillOpacity="0.01" />
      <line
        x1="17.832"
        y1="4.13137"
        x2="4.13172"
        y2="17.8317"
        stroke="#333333"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <line
        x1="4.29934"
        y1="4.47827"
        x2="17.9997"
        y2="18.1786"
        stroke="#333333"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default NavClose
